module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ferry Suhandri","short_name":"Ferry S","description":"Software Engineer dari Solok. Membahas pemrograman, pengalaman hidup, dan pandangan pribadi","lang":"id","start_url":"/","background_color":"#663399","theme_color":"#3948DF","display":"standalone","crossOrigin":"anonymous","icon_options":{"purpose":"any maskable"},"icon":"src/images/avatar.png","cache_busting_mode":"none","icons":[{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-EVGFGLDYR8"],"gtagConfig":{"anonymize_ip":true,"cookie_domain":"ferry.vercel.app","cookie_flags":"SameSite=None;Secure","cookie_expires":31104000},"pluginConfig":{"exclude":["/tags/**","/search","/archive","/404","/404.html","/page/**","^[^?]+(\\?.*)",".json$","[?]","[#]","[%]"],"head":false,"respectDNT":false,"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
