/************************
 * Made by [MR Ferry™]  *
 * on April 2020        *
 ************************/

export const signed = `
███████╗███████╗██████╗ ██████╗ ██╗   ██╗    ███████╗██╗██╗  ██╗██╗   ██╗███╗   ███╗██████╗  █████╗ ███╗   ██╗ ██████╗ 
██╔════╝██╔════╝██╔══██╗██╔══██╗╚██╗ ██╔╝    ██╔════╝██║██║ ██╔╝██║   ██║████╗ ████║██╔══██╗██╔══██╗████╗  ██║██╔════╝ 
█████╗  █████╗  ██████╔╝██████╔╝ ╚████╔╝     ███████╗██║█████╔╝ ██║   ██║██╔████╔██║██████╔╝███████║██╔██╗ ██║██║  ███╗
██╔══╝  ██╔══╝  ██╔══██╗██╔══██╗  ╚██╔╝      ╚════██║██║██╔═██╗ ██║   ██║██║╚██╔╝██║██╔══██╗██╔══██║██║╚██╗██║██║   ██║
██║     ███████╗██║  ██║██║  ██║   ██║       ███████║██║██║  ██╗╚██████╔╝██║ ╚═╝ ██║██████╔╝██║  ██║██║ ╚████║╚██████╔╝
╚═╝     ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝       ╚══════╝╚═╝╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚═╝╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═══╝ ╚═════╝ 
`;

export function Sign() {
  console.log(signed);
}
